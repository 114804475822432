import { render, staticRenderFns } from "./zhibiao-detail.vue?vue&type=template&id=502e8b47&scoped=true&"
import script from "./zhibiao-detail.vue?vue&type=script&lang=ts&"
export * from "./zhibiao-detail.vue?vue&type=script&lang=ts&"
import style0 from "./zhibiao-detail.vue?vue&type=style&index=0&id=502e8b47&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502e8b47",
  null
  
)

export default component.exports