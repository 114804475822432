








































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import { getZhibiaoDetail } from "@/request/doctor";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends Vue {
  private data: any = {};
  private get patient() {
    return this.$store.state.patient;
  }
  private getData() {
    const params = {
      warning_id: this.data.warning_id,
    };
    getZhibiaoDetail(this, params).then((res: any) => {
      this.data = res;
    });
  }
  mounted() {
    if (this.$route.query.warning_id) {
      this.data.warning_id = this.$route.query.warning_id;
      this.getData();
    }
  }
}
